import './App.css';
import axios from 'axios'

import {Layout,Button, Input, Form, Tag, Toast, Descriptions, Switch,  Slider, Popover, Timeline, Banner} from '@douyinfe/semi-ui';
import {useState, useEffect, useRef} from 'react'

var GATEWAY_IP = "192.168.1.157";
const API_VERSION = "v1";
const API_PORT = 9000;

function makeApiURL(path) {
  return ("http://" + GATEWAY_IP + ":" + API_PORT + "/" + API_VERSION + "/" + path);
}

function cloneObject(obj) {
  let str = JSON.stringify(obj);
  return JSON.parse(str);
}

function GPUT(path, data, callback) {
  axios({
    method: "put",
    url: makeApiURL(path), 
    timeout: 1000,
    data: data,
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then(response => {
    if (response.status === 200) {
      callback(response.data);
      return response;
    }
    throw new Error("failed");
  })
  .catch(error => {
    console.log("请求失败 " + error);
    Toast.error("请求失败，路径：" + path + "   错误：" + error);
  });
}

// 名称属性
function NameProperty({deviceId, prop}) {
  const name = prop.value.var;

  const onChange = str => {
        //修改指定网关的指定设备的指定属性
        prop.value.var = str;
        wsRequest(["devices", deviceId, "props", prop.id].join("/"), "PUT", prop,
          (response) => {
            // setName(JSON.parse(response).value.var);
          });
  };
  return (
    <Descriptions.Item itemKey="设备名称">
      <Input value={name} onChange={onChange}></Input>
    </Descriptions.Item>)
}

//滑动条属性
function SliderProperty({name, deviceId, prop}) {
  const max = Number(prop.metadata.max.var);
  const min = Number(prop.metadata.min.var);
  const value = prop.value.var;

  const onChange = v => {
    if (v > max) {
      v = max;
    }
    if (v < min) {
      v = min;
    }
    let newProp = cloneObject(prop);
    newProp.value.var = v;
    wsRequest(["devices", deviceId, "props", prop.id].join("/"),
      "PUT", newProp, 
      (response) => {
        // setValue(JSON.parse(response).value.var);
      });
  };
  return (
    <Descriptions.Item itemKey={name}>
      {value}
      <Slider style={{width: 140}} showBoundary={true} 
        min={min} 
        max={max} 
        step={prop.metadata.step.var}
        value={value} 
        onChange={onChange}>
      </Slider>
    </Descriptions.Item>)
}

// 开关属性
function ToggleProperty({deviceId, prop}) {
  const onChange = (ischeck) => {
        //修改指定网关的指定设备的指定属性
        let newProp = cloneObject(prop);
        newProp.value.var = ischeck;
        wsRequest(["devices", deviceId, "props", newProp.id].join("/"),
          "PUT", newProp, (response) => {
            // setChecked(JSON.parse(response).value.var);
          });
  };

  return (
    <Descriptions.Item itemKey="开关状态">
      <Switch checked={prop.value.var} onChange={onChange}></Switch>
    </Descriptions.Item>)
}

function DeviceComponent({device}) {
  const style = {
        boxShadow: 'var(--semi-shadow-elevated)',
        backgroundColor: 'var(--semi-color-bg-2)',
        borderRadius: '4px',
        padding: '10px',
        margin: '10px',
        width: '250px',
    };
  return (
    <Descriptions style={style} >
      <Descriptions.Item itemKey="设备标识">{device.id}</Descriptions.Item>
      <Descriptions.Item itemKey="设备类型">{device.type}</Descriptions.Item>
      {device.props.map((prop, index) => {
        switch (prop.name) {
          case "name":
            return (<NameProperty key={prop.id} deviceId={device.id} prop={prop}></NameProperty>)
          case "toggle":
            return (<ToggleProperty key={prop.id} deviceId={device.id} prop={prop} />)
          case "brightness":
            return (<SliderProperty name="亮度" key={prop.id} deviceId={device.id} prop={prop} />)
          default:
            return null;
        }
      })}
      <Descriptions.Item itemKey="原始数据">
        <Popover
          style={{ padding: 12 }}
          content={<div>{JSON.stringify(device)}</div>}>
          <Tag>原始数据</Tag>
        </Popover>
      </Descriptions.Item>
    </Descriptions>
  )
}

function DeviceListComponent({devices}) {
  const view = !Array.isArray(devices) ? null:
        devices.map((device, i) => {
          return (
            <DeviceComponent key={i} device={device}></DeviceComponent>
            );
        });
  return (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {view}
    </div>
  );
}

function wsRequest(path, method, sendData, callback) {
    let ws = new WebSocket('ws://' + GATEWAY_IP + ':' + API_PORT + '/v1/' + path + '?method=' + method);
    ws.onopen = () => {
      if (sendData != null) {
        ws.send(JSON.stringify(sendData));
      } else {
        ws.send("{}");
      }
    }
    ws.onmessage = (msg) => {
      callback(msg.data);
      ws.close();
    }
    ws.onclose = () => {
    }
    ws.onerror = (error) => {
      console.log("wsRequest error");
      console.log(error);
      Toast.error("请求失败");
    }
}

let ws = null;
let lastToastId = 0;
let lastToastTimeoutId = 0;

function App() {

  const CONNECT_ON = "on"; //已连接
  const CONNECT_OFF = "off"; //连接关闭

  const [devices, setDevices] = useState([]);
  const [ip, setIp] = useState(GATEWAY_IP);
  const [title, setTitle] = useState("def");
  const [eventBody, setEventBody] = useState("");
  const [connectState, setConnectState] = useState(CONNECT_ON);

  const link = useRef(false);

  const updateDevices = () => {
    wsRequest("devices", "GET", null, (response) => {
      setDevices(JSON.parse(response));
    });
  }

  const updateProperty = (deviceId, prop) => {
    for (let i = 0; i < devices.length; ++i) {
      if (deviceId !== devices[i].id) {
        continue;
      }
      let dev = devices[i];
      for (let p of dev.props) {
        if (p.id !== prop.id) {
          continue;
        }
        // console.log("find prop " + p.value.var + "=>" + prop.value.var);
        p.value = prop.value;
        setDevices([... devices]);
      }
    }
  }

  const onWsOpen = () => {
    link.current = true;
    setConnectState(CONNECT_ON);
  }
  const onWsMessage = (data) => {
      let e = JSON.parse(data.data);
      updateProperty(e.source, e.data.var);
      setEventBody(e);
  }
  const onWsClose = (data) => {
    link.current = false;
    setTimeout(() => {
      if (!link.current) {
        setConnectState(CONNECT_OFF);
      }
    }, 1000);
  }
  const onWsError = (data) => {
    link.current = false;
    setTimeout(() => {
      if (!link.current) {
        Toast.error("连接网关失败");
      }
    }, 1000);
  }

  const bindWebsocket = (ws) => {
    ws.onopen = onWsOpen;
    ws.onmessage = onWsMessage;
    ws.onerror = onWsError;
    ws.onclose = onWsClose;
  }

  useEffect(() => {
    if (ws != null) {
      ws.close();
    }
    ws = new WebSocket('ws://' + ip + ':' + API_PORT + '/v1/events');
    bindWebsocket(ws);
    updateDevices();
  }, []);

  useEffect(() => {
    bindWebsocket(ws);
  });

  const onIpChange = (value) => {
    GATEWAY_IP = value.trim();
    setIp(GATEWAY_IP);
    console.log("onIpChange");
    updateDevices();
  };

  const onRefresh = () => {
    console.log("onRefresh");
    updateDevices();
  };

  const onClickAddDevice =() => {
    devices[0].props[0].value.var ="新添加的设备";
    wsRequest("devices", "POST", devices[0], (response) => {
      onRefresh();
    });
  }

  const onClickRemoveDevice = () => {
     wsRequest(["devices", devices[0].id].join("/"), "DELETE", null, (response) => {
       onRefresh();
     })
  }

  const onClickTitle = () => {
    setTitle(title + 1);
  }

  const banner = () => {
    if (connectState === CONNECT_OFF && Array.isArray(devices) && devices.length > 0) {
        return (<Banner 
            type="danger"
            description="断开连接，请刷新！">
        </Banner>)
    }
    return null;
  }
  
  return (
        <Layout className="components-layout-demo" style={{margin: 10}}>
          {banner()}
          <Layout>
            <Layout.Content>
              <Form layout="horizontal">
                <Input addonBefore="网关IP" value={ip} minLength={4} style={{ width: 200 }} onChange={onIpChange}/>
               {/* <Button type="primary" onClick={onRefresh}>刷新</Button> */}
              </Form>
              <DeviceListComponent devices={devices}/>
              <Button onClick={onClickAddDevice}>测试添加第一个设备</Button>
              <Button onClick={onClickRemoveDevice}>测试删除第一个设备</Button>
              <Timeline mode="left">
                {eventBody === "" ? "" : (
                <Timeline.Item time={new Date(eventBody.time * 1000).toLocaleString()}
                  extra={JSON.stringify(eventBody)}>
                  {eventBody.type}
                </Timeline.Item>
                )}
              </Timeline>
            </Layout.Content>
          </Layout>
        </Layout>
    )
}

export default App;
